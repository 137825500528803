import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [9,2,5,7];

export const dictionary = {
		"/agent": [~26,[9],[10]],
		"/agent/developers": [27,[9],[10]],
		"/agent/orders": [~28,[9],[10]],
		"/agent/routes": [~29,[9],[10]],
		"/agent/routes/[blueprint]": [~30,[9],[10]],
		"/auth/auth0/callback": [~31,[],[11]],
		"/[[lang=language]]/booking": [~14,[2],[3,4]],
		"/[[lang=language]]/booking/[booking]": [~15,[2,,5],[3,4]],
		"/[[lang=language]]/booking/[booking]/passengers": [~16,[2,,5],[3,4]],
		"/[[lang=language]]/booking/[booking]/payment/[payment]": [~17,[2,,5],[3,4]],
		"/[[lang=language]]/booking/[booking]/tickets": [~18,[2,,5],[3,4]],
		"/[[lang=language]]/carriers": [~19,[2],[3]],
		"/[[lang=language]]/carriers/raw": [~21,[2],[3]],
		"/[[lang=language]]/carriers/[slug]": [~20,[2],[3]],
		"/[[lang=language]]/order/[order]": [~22,[2,6],[3]],
		"/[[lang=language]]/routes": [~23,[2],[3]],
		"/[[lang=language]]/routes/[route]/[[blueprint]]": [~24,[2],[3]],
		"/[[lang=language]]/search": [~25,[2,7],[3,8]],
		"/[[lang=language]]": [~12,[2],[3]],
		"/[[lang=language]]/[slug]": [~13,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';