import { shouldPolyfill } from '@formatjs/intl-durationformat/should-polyfill'
import {
  PUBLIC_SENTRY_DSN,
  PUBLIC_SENTRY_ENVIRONMENT
} from '$env/static/public'
import {
  handleErrorWithSentry,
  setTag,
  init as sentryInit
} from '@sentry/sveltekit'

sentryInit({
  dsn: PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: PUBLIC_SENTRY_ENVIRONMENT
})

const client = document.cookie.match(/(?:^|;\s*)aa.client=(.+?)(?:;|$)/)
if (client) setTag('client', client[1])

const session = document.cookie.match(/(?:^|;\s*)aa.session=(.+?)(?:;|$)/)
if (session) setTag('session', session[1])

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()

export async function init() {
  if (shouldPolyfill()) {
    await import('@formatjs/intl-durationformat/polyfill-force')
  }
}
